



















































































































import { Component, Prop, Watch } from 'vue-property-decorator';
import ButtonComponent from '@/components/ButtonComponent.vue';
import { Data } from '@/utils/types/WidgetData';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import WidgetCardMapping from '@/utils/widgets/WidgetCardMapping';
import { deepGet } from '@/utils/ObjectHelpers';
import FontAwesomeComponent from '@/components/FontAwesomeComponent.vue';
import VideoPlayerComponent from '@/components/video-player/VideoPlayerComponent.vue';
import Session from '@/models/graphql/Session';
import ActionRuleService from '@/services/ActionRuleService';
import ActionRule from '@/utils/enums/ActionRule';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import LoadingSpinnerComponent from '@/components/LoadingSpinnerComponent.vue';

@Component({
  components: {
    VideoPlayerComponent,
    FontAwesomeComponent,
    ButtonComponent,
    LoadingSpinnerComponent,
  },
  inheritAttrs: false,
})
/* eslint-disable no-underscore-dangle */
export default class TabItemWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({
    required: false,
    default: true,
  })
  readonly displayRecommendedBadge!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  readonly displayFeaturedBorder!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  readonly displayLiveBadge!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  readonly displayExpirationBadge!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  readonly displayInterestedBadge!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  readonly displaySpeakers!: boolean;

  protected baseStoreName = 'TabItemWidgetStore';

  @Prop({
    required: false,
    default: () => [],
  })
  private data!: Data[];

  @Prop({
    required: false,
    default: false,
  })
  private readonly scroll!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly showVideo!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private readonly btnTitle!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly btnRoute!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly btnRouteData!: Data[] | null;

  @Prop({
    required: false,
    default: null,
  })
  private readonly displayExtraProperties!: Data[] | null;

  @Prop({
    required: false,
    default: null,
  })
  private readonly authBtnTitle!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly authSubtitle!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly authTitle!: string;

  @Prop({
    required: false,
    default: false,
  })
  private readonly authRequired!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private readonly cardRoute!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly cardType!: string;

  @Prop({
    required: false,
    default: '',
  })
  private readonly mapUrl!: string;

  @Prop({
    required: false,
    default: '',
  })
  private readonly schemaCode!: string;

  @Prop({
    required: false,
    default: false,
  })
  private readonly displayHall!: boolean;

  @Prop({
    required: false,
    default: true,
  })
  private readonly preload!: boolean;

  private WidgetMapping = WidgetCardMapping;

  private ActionRuleService = ActionRuleService;

  private ActionRule = ActionRule;

  private get handleBaseUrl(): string {
    if (this.btnRoute) {
      let r = this.btnRoute;
      const matches = this.btnRoute.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '')
            .trim();
          if ('btnRouteData' in this.$props && this.btnRouteData && this.btnRouteData.length > 0) {
            const newValue = deepGet(this.$props, prop);
            if (newValue) {
              r = r.replaceAll(m, newValue);
            }
          }
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
      }
      return r;
    }
    return '';
  }

  private get elements(): Data[] {
    return this.data;
  }

  private get session(): Session {
    if (this.isSessionVideo && this.data.length > 0) {
      return this.data[0] as unknown as Session;
    }
    return Session.hydrate({});
  }

  private get isLoading(): boolean {
    return this.$store.getters[`${this.widgetStorePath}/isLoading`] || false;
  }

  private get isSessionVideo(): boolean {
    return this.cardType === 'videoSession'
      && this.showVideo
      && this.elements.length > 0
      && ActionRuleService
        .isRuleValid(this.elements[0]._actions as Array<{
          key: string;
          value: string;
        }>, ActionRule.CAN_VIEW_LIVE_VIDEO);
  }

  getClass(index: number, tabItemsLength: number): string {
    if (this.cardType === 'article') {
      if (this.isMobile) return 'card-container col-12';
      switch (tabItemsLength) {
        case 1:
          return 'card-container col-12';
        case 2:
          return 'card-container col-6';
        default:
          break;
      }
      if ((tabItemsLength - 1) === index && index % 3 === 1) {
        return 'card-container col-12';
      }
      if (index % 3 === 0) {
        return 'card-container col-12';
      }
      return 'card-container col-6';
    }
    return this.classes || 'col-11 col-sm-7 col-md-6 col-lg-6 col-xl-3';
  }

  created(): void {
    this.setDataConfig();
  }

  @Watch('isReadyToDisplay')
  @Watch('elements')
  private hideTab(): void {
    this.$nextTick(() => {
      if (this.isReadyToDisplay && this.elements.length === 0 && this.preload) {
        this.$root.$emit('hide-pill', this.widget.uid);
      }
    });
  }

  private openTheaterForSession(session: Session): void {
    this.$router.push({
      name: 'session-detail',
      params: { sessionId: session.uid },
      query: { openTheaterMode: 'true' },
    });
  }
}
